
import { defineComponent } from 'vue';
import useValidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import _ from 'lodash';
import { ReviewQuestion } from '@/models/ReviewQuestionModel';
import toasts from '@/utils/toasts';

export default defineComponent({
  emits: ['flagReview', 'submit'],
  props: {
    isCurrent: { default: false },
    questions: {
      default: () => {
        return [] as unknown as Array<ReviewQuestion>;
      },
    },
  },
  data() {
    return {
      v$: useValidate(),
      form: {
        reviewAnswers: [] as any,
      },
      isFlagged: false,
      ratingTexts: ['Very bad', 'Disappointed', 'Fair', 'Good', 'Very Good'],
      invalidData: [] as any,
    };
  },
  validations() {
    // let form = { reviewAnswers : {}};
    // _.forEach(this.questions, function(value, key) {
    //     form.reviewAnswers[key] = {
    //         required
    //     };
    // });
    // return form;
  },
  methods: {
    isFormValid() {
      const vm = this;
      this.invalidData = [];

      _.forEach(this.questions, function (value: ReviewQuestion, key) {
        if (
          value?.is_required === 'yes' &&
          (!vm.form.reviewAnswers[key] ||
            typeof vm.form.reviewAnswers[key] === 'undefined' ||
            vm.form.reviewAnswers[key] === '')
        ) {
          vm.invalidData.push(key);
        }
      });

      if (this.invalidData.length) {
        return false;
      }

      return true;
    },
    async notReviewing() {
      var answers = [] as any;

      _.forEach(this.form.reviewAnswers, (value, key) => {
        const question = this.questions[key];
        answers.push({
          id: question.id,
          code: question.code,
          type: question.type,
          options: question.options,
          question: question.question,
          answer: null,
          default_answer: question.default_answer,
        });
      });

      return toasts.confirm(
        "Are you sure you want to leave a 'No Review' for the tenant? By continuing, any selection below will be disregarded and be taken to the next step",
        ({ isConfirmed }) => {
          if (isConfirmed) {
            this.$emit('submit', {
              review_answers: answers,
              flag: 'no_review',
            });
          } else {
            return false;
          }
        }
      );
    },
    flagReview() {
      this.isFlagged = !this.isFlagged;
      this.$emit('flagReview', this.isFlagged);
    },
    submit() {
      // this.v$.$validate() // checks all inputs

      if (!this.isFormValid()) {
        return false;
      }

      var answers = [] as any;

      _.forEach(this.form.reviewAnswers, (value, key) => {
        const question = this.questions[key];
        answers.push({
          id: question.id,
          code: question.code,
          type: question.type,
          options: question.options,
          question: question.question,
          answer: value,
          default_answer: question.default_answer,
        });
      });

      this.$emit('submit', { review_answers: answers });
    },
    clear() {
      this.form.reviewAnswers = [];
      // this.v$.$reset();
    },
    rateFiveStar() {
      _.forEach(this.questions, (value, key) => {
        this.form.reviewAnswers[key] = value?.default_answer;
      });
    },
  },
});
