
import { defineComponent } from 'vue';
import useValidate from '@vuelidate/core';
import toasts from '@/utils/toasts';

export default defineComponent({
  props: {
    isCurrent: { default: false },
  },
  data() {
    return {
      v$: useValidate(),
    };
  },
  emits: ['uploadLedgerFile', 'uploadSupportFile', 'removeSupportFile'],
  methods: {
    handleRemove() {
      this.$emit('uploadLedgerFile', null);
    },
    onChange(file) {
      let valid = this.validateFile(file);
      if (valid) {
        this.$emit('uploadLedgerFile', file.raw);
      }
    },
    onChangeSupportInput(file) {
      let valid = this.validateSupportFile(file);
      if (valid) {
        this.$emit('uploadSupportFile', file.raw);
      }
    },
    handleRemoveSupportDocument(file) {
      this.$emit('removeSupportFile', file);
    },
    validateFile(file) {
      if (file.raw.type !== 'application/pdf') {
        toasts.error({}, 'The file must be pdf only!');
        (this.$refs.uploader as any).handleRemove();
        return false;
      }

      if (file.raw.size / 1024 / 1024 > 5) {
        toasts.error({}, 'PDF file size can not exceed 5MB!');
        (this.$refs.uploader as any).handleRemove();
        return false;
      }

      return true;
    },
    validateSupportFile(file) {
      const acceptedFiles = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats',
        'officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats',
        'officedocument.spreadsheetml.sheet',
        'image/jpeg',
        'image/png',
      ];

      console.log('file.raw.type', file.raw.type);

      if (!acceptedFiles.includes(file.raw.type)) {
        toasts.error({}, 'The invalid file type.');
        (this.$refs.uploader as any).handleRemove();
        return false;
      }

      if (file.raw.size / 1024 / 1024 > 5) {
        toasts.error({}, 'File size can not exceed 5MB!');
        (this.$refs.uploader as any).handleRemove();
        return false;
      }

      return true;
    },
  },
});
