
import { defineComponent } from 'vue';
import useValidate from '@vuelidate/core';
import { TenantDetailsValidations } from '@/views/new-design/validations/TenantReview';
import moment from 'moment';
import maskUtil from '@/utils/mask';
import text from '@/utils/text';

export default defineComponent({
  props: {
    isCurrent: {
      type: Boolean,
      default: false,
    },
    useExistingTenant: {
      type: Boolean,
      default: false,
    },
    hasReference: {
      type: Boolean,
      default: false,
    },
    agreed: {
      type: Boolean,
      default: false,
    },
    agreedTick: {
      type: Boolean,
      default: true,
    },
    isNewTenant: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['findExistingTenant', 'changeDetails', 'submit', 'agreed'],
  data() {
    return {
      v$: useValidate(),
      form: {
        first_name: null,
        last_name: null,
        date_of_birth: null,
        mobile_number: null,
        email: null,
      },
    };
  },
  computed: {
    maskingMobile() {
      return maskUtil.mobile.aus;
    },
    getAgreed: {
      get() {
        return this.agreed;
      },
      set(value) {
        this.$emit('agreed', value);
      },
    },
  },
  validations() {
    return TenantDetailsValidations;
  },
  watch: {
    form: {
      handler(value) {
        if (value.first_name && value.last_name && value.date_of_birth) {
          this.findExistingTenant();
        }
      },
      deep: true,
    },
    agreed(value) {
      this.$emit('agreed', value);
    },
  },
  methods: {
    hasNoValue(value) {
      return value === undefined || value === null;
    },
    submit() {
      this.v$.$validate(); // checks all inputs

      if (this.v$.$error) {
        return false;
      }

      this.$emit('submit', this.form);
    },
    clear() {
      this.v$.$reset();

      this.form.first_name = null;
      this.form.last_name = null;
      this.form.date_of_birth = null;
      this.form.mobile_number = null;
      this.form.email = null;
    },
    setInitialData(data) {
      this.form.first_name = data.first_name;
      this.form.last_name = data.last_name;
      this.form.date_of_birth = data.date_of_birth;
      this.form.mobile_number = data.mobile_number;
      this.form.email = data.email;
    },
    findExistingTenant() {
      this.$emit('findExistingTenant', {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        date_of_birth: moment(
          (this.form as any)?.date_of_birth as string,
          'YYYY-MM-DD'
        )
          .format('YYYY-MM-DD')
          .toString(),
      });
    },
    handleChangeDetail(value, key) {
      this.$emit('changeDetails', value, key);
    },
    isNumber(e){
      return text.isNumber(e);
    }
  },
});
