
import { defineComponent } from 'vue';
import Navigator from '@/views/new-design/layout/Navigator.vue';
import Stepper from '@/views/new-design/components/Stepper.vue';
import TenantDetails from '@/views/new-design/pages/SubmitTenantReview/TenantDetails.vue';
import PropertyDetails from '@/views/new-design/pages/SubmitTenantReview/PropertyDetails.vue';
import TenantReviewForm from '@/views/new-design/pages/SubmitTenantReview/TenantReview.vue';
import UploadLedger from '@/views/new-design/pages/SubmitTenantReview/UploadLedger.vue';
import SubmittedReview from '@/views/new-design/pages/SubmitTenantReview/SubmittedReview.vue';
import Suggestion from '@/views/new-design/pages/SubmitTenantReview/Suggestion.vue';

import { Actions as StoreActions } from '@/store/enums/StoreEnums';
import { Getters, Actions } from '@/store/enums/TenantReviewEnums';
import {
  Getters as RQGetters,
  Actions as RQActions,
} from '@/store/enums/ReviewQuestionsEnums';
import {
  Getters as TGetters,
  Actions as TActions,
  Mutations as TMutations,
} from '@/store/enums/TenantEnums';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
  Getters as ReferenceRequestGetters,
  Actions as ReferenceRequestActions,
} from '@/store/enums/ReferenceRequestEnums';

import toasts from '@/utils/toasts';
import moment from 'moment';
import _ from 'lodash';
import store from '@/store';
import { TenantReview } from '@/models/TenantReviewModel';

import PDFGenerator from '@/views/new-design/pages/ViewTenantReview/PDFGenerator.vue';
import LedgerReport from '@/views/new-design/pages/ViewTenantReview/Report/Ledger.vue';
import { numberFormat, ucwords } from '@/utils/text';

const initFormValue = {} as unknown as TenantReview;

export default defineComponent({
  components: {
    Navigator,
    Stepper,
    Step1: TenantDetails,
    Step2: PropertyDetails,
    Step3: TenantReviewForm,
    Step4: UploadLedger,
    Submitted: SubmittedReview,
    Suggestion,
    PDFGenerator,
    LedgerReport,
  },
  data: () => ({
    currentStep: 1,
    formData: initFormValue,
    reviewFormErrorMessages: [] as any as [],
    maxStep: 4,
    isSubmitted: true,
    referenceNo: null as any,
    referenceLink: null as any,
    isSearching: false as any,
    hideSuggestion: false as any,
    loading: false,
    supportFiles: [] as Array<File>,
    debounceFindExistingTenant: Function() as any,
    disableTenantDetails: false,
    hasPersonalDetails: false,
    agreed: false,
    disableAgreedTick: true,
    pdfFile: '',
    averageRating: '0',
    isNewTenant: true,
    hideAddressLookup: false,
  }),
  computed: {
    disableNextBtn() {
      return !this.agreed;
    },
    ...mapGetters({
      actionErrors: Getters.GET_TENANT_REVIEW_ACTION_ERROR,
      tenantReview: Getters.GET_TENANT_REVIEW,
      tenantReviews: Getters.GET_ALL_TENANT_REVIEWS,
      reviewQuestions: RQGetters.GET_REVIEW_QUESTIONS,
      ledgerFile: Getters.GET_TENANT_REVIEW_LEDGER,
      uploadFileErrors: Getters.GET_UPLOAD_FILE_ERROR,
      tenantReference: ReferenceRequestGetters.GET_TENANT_REFERENCE,
      tenant: TGetters.GET_TENANT,
      existingTenant: TGetters.GET_EXISTING_TENANT,
    }),
    showingSuggestion() {
      return (
        this.existingTenant?.id && this.currentStep == 1 && this.isSearching
      );
    },
    foundExistingTenant: {
      get() {
        return this.existingTenant;
      },
      set(value) {
        this.setExistingTenant(value);

        this.isNewTenant = false;
      },
    },
    isPersonalDetailsFilled() {
      return (
        this.formData?.first_name ||
        this.formData?.last_name ||
        this.formData?.date_of_birth ||
        this.formData?.email ||
        this.formData?.mobile_number
      );
    },
    reviewsCount() {
      return typeof this.tenantReviews !== 'undefined'
        ? this.tenantReviews.length
        : 0;
    },
    googleStaticMapImageSrc() {
      const src = (this.tenantReview as any)?.map_static_src;
      return src != '' ? src : require('@/assets/new-design/images/map.png');
    },
  },
  methods: {
    ...mapActions({
      uploadTenantLedger: Actions.UPLOAD_TENANT_REVIEW_LEDGER,
      uploadSupportDocuments: Actions.UPLOAD_TENANT_REVIEW_SUPPORT_DOCUMENTS,
      createTenantReview: Actions.CREATE_TENANT_REVIEW,
      searchTenant: TActions.SEARCH_TENANT,
      getReviewQuestions: RQActions.FETCH_REVIEW_QUESTION,
      fetchTenantReference: ReferenceRequestActions.FETCH_TENANT_REFERENCE,
      findExistingTenantById: TActions.FIND_EXISTING_TENANT_BY_ID,
      findExistingTenant: TActions.FIND_EXISTING_TENANT,
      removeStoredTenantReference:
        ReferenceRequestActions.REMOVE_STORED_REFERENCE,
      savePDFReport: Actions.SAVE_TENANT_REPORT_PDF,
    }),
    ...mapMutations({
      setExistingTenant: TMutations.SET_EXISTING_TENANT,
    }),
    goPreviousStep() {
      if (this.currentStep > 1) {
        if (this.currentStep == 2) {
          (this.$refs.step2 as any).reset();
        }

        this.currentStep--;
      }
    },
    validateStep(data) {
      this.formData = { ...this.formData, ...data };
      this.$nextTick(() => {
        this.currentStep++;
      });
    },
    goNextStep() {
      if (this.currentStep == 1) {
        (this.$refs.step1 as any).submit();
      }

      if (this.currentStep == 2) {
        (this.$refs.step2 as any).submit();
      }

      if (this.currentStep == 3) {
        (this.$refs.step3 as any).submit();
      }
    },
    resetForm() {
      this.formData = initFormValue;
      this.isSearching = false;

      (this.$refs.step1 as any)?.clear();
      (this.$refs.step2 as any)?.clear();
      (this.$refs.step3 as any)?.clear();
      (this.$refs.step4 as any)?.handleRemove();

      this.referenceNo = null;
      this.referenceLink = null;
      this.currentStep = 1;
    },
    clearTenantDetails() {
      this.agreed = false;
      this.formData.first_name = '';
      this.formData.last_name = '';
      this.formData.email = '';
      this.formData.date_of_birth = '';
      this.formData.mobile_number = '';
      this.formData.address_line = '';
      this.formData.city = '';
      this.formData.post_code = '';
      this.formData.state = '';

      this.foundExistingTenant = null;
      this.isNewTenant = true;
      this.hideAddressLookup = false;

      (this.$refs.step1 as any)?.clear();
      (this.$refs.step2 as any)?.clear();
    },
    handleUploadLedgerFile(file) {
      this.formData = { ...this.formData, ...{ ledger_file: file } };
    },
    handleUploadSupportFile(file: any) {
      this.supportFiles.push(file);
    },
    handleRemoveSupportDocument(file: any) {
      const fileObject: any = file;
      const fileIndex = this.supportFiles.indexOf(fileObject);

      this.supportFiles.splice(fileIndex, 1);
    },
    handleFlagReview(value) {
      this.formData.is_flagged = value;
    },
    async submit() {
      if (this.loading) return;

      this.loading = true;

      if (!(this.formData as any).ledger_file) {
        toasts.error({}, 'Please add the tenant ledger.', () => {
          this.loading = false;
        });
        return false;
      }

      if (this.$route.query.ref) {
        (this.formData as any).request_reference_id = this.$route.query.ref;
      }

      (this.formData as any).date_of_birth = moment(
        (this.formData as any)?.date_of_birth as string
      )
        .format('YYYY-MM-DD')
        .toString();

      (this.formData as any).move_in_date = moment(
        (this.formData as any)?.move_in_date as string,
        'YYYY-MM-DD'
      )
        .format('YYYY-MM-DD')
        .toString();

      (this.formData as any).move_out_date = moment(
        (this.formData as any)?.move_out_date as string,
        'YYYY-MM-DD'
      )
        .format('YYYY-MM-DD')
        .toString();

      (this.formData as any).ledger_file = (await this.uploadTenantLedger(
        (this.formData as any).ledger_file
      )) as string;

      await this.createTenantReview(this.formData)
        .then(async () => {
          this.resetForm();
          this.referenceNo = await this.tenantReview.reference_no;
          this.referenceLink = `${process.env.VUE_APP_BASE_URL}/tenant-report/${this.tenantReview.reference_no}`;

          this.isSubmitted = true;

          await this.removeStoredTenantReference();

          this.handleGenerateReport();

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          if (this.actionErrors != null) {
            const { errors, message } = this.actionErrors;
            toasts.error(errors, message);
          }
        });

      this.supportFiles.forEach(async (file) => {
        const params = {
          file: file,
          refNo: await this.referenceNo,
        };

        await this.uploadSupportDocuments(params);
      });
    },
    async handleFetchTenantReference(id) {
      if (!id) return;

      this.disableTenantDetails = false;

      await this.fetchTenantReference({ id: id }).then(() => {
        this.disableTenantDetails = true;
      });
    },
    async findTenantById(id) {
      await this.findExistingTenantById(id);
    },
    async loadInitialData() {
      this.getReviewQuestions();
      this.resetForm();

      this.isSubmitted = false;

      if (await this.$route.query.ref) {
        await this.handleFetchTenantReference(await this.$route.query.ref);
        const data = this.tenantReference.data;

        const tenant = {
          first_name: data.tenant_first_name,
          last_name: data.tenant_last_name,
          date_of_birth: data.tenant_date_of_birth,
        };

        const property = {
          city: data.city,
          address: data.address_line,
          state: data.state,
          country: data.country,
          post_code: data.post_code,
        };

        (this.$refs.step1 as any).setInitialData(tenant);
        (this.$refs.step2 as any).setInitialData(property);

        this.isNewTenant = false;
        this.hideAddressLookup = true;
      }

      if (this.$route.query.tenant) {
        await this.findTenantById(this.$route.query.tenant);

        const tenant = {
          first_name: this.tenant.first_name,
          last_name: this.tenant.last_name,
          date_of_birth: this.tenant.date_of_birth,
          email: this.tenant.email,
          mobile_no: this.tenant.mobile_number,
        };

        console.log('TENANT', tenant);

        this.isSearching = false;
        (this.$refs.step1 as any).setInitialData(tenant);

        this.formData = {
          ...this.formData,
          ...tenant,
        };

        this.agreed = true;
        this.isNewTenant = false;
      }
    },
    handleCreateNewTenantReview() {
      this.isSubmitted = false;
      this.showingSuggestion = false;
      this.resetForm();
      this.$router.push(this.$route.path);
    },
    async handleFindExistingTenant(payload) {
      this.isSearching = true;
      await this.findExistingTenant(payload);
    },
    useExistingTenant(data) {
      this.isSearching = false;
      this.hideSuggestion = true;
      this.isNewTenant = false;
      (this.$refs.step1 as any).setInitialData(data);
    },
    handleOnChangeDetails(el, key) {
      this.formData[key] = el.target.value;

      if (this.isPersonalDetailsFilled) {
        this.hasPersonalDetails = true;
      } else {
        this.hasPersonalDetails = false;
      }

      this.hideSuggestion = false;
    },
    handleAgreed(value) {
      this.agreed = value;
    },
    computeAverageRating() {
      let sumRating = 0;
      let totalReviews = 0;
      if (
        typeof this.tenantReviews !== 'undefined' &&
        this.tenantReviews.length > 0
      ) {
        this.tenantReviews.map((review: TenantReview) => {
          if (review.status === 'published') {
            sumRating = sumRating + parseFloat(review.average_rating);
            totalReviews++;
          }
        }, 0);
      }
      const average = totalReviews > 0 ? sumRating / totalReviews : 0;
      this.averageRating = average.toFixed(1);
    },
    handleGenerateReport() {
      (this.$refs.sendPdfReport as any).generatePDf();
    },
    async handleBeforeDownload(pdf) {
      if (this.pdfFile !== (await pdf)) {
        this.pdfFile = await pdf;
      }
    },
  },
  created() {
    this.debounceFindExistingTenant = _.debounce((payload) => {
      this.handleFindExistingTenant(payload);
    }, 1000);
  },
  mounted() {
    // show page loading
    setTimeout(() => {
      store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 500);

    this.loadInitialData();
  },

  setup() {
    // show page loading
    store.dispatch(StoreActions.ADD_BODY_CLASSNAME, 'page-loading');
  },
  watch: {
    existingTenant(value) {
      if (value?.id) {
        this.disableAgreedTick = false;
        this.agreed = true;
        this.isNewTenant = false;

        (this.$refs.step1 as any).setInitialData(value);
      } else {
        // this.agreed = false;
        this.disableAgreedTick = true;
      }
    },
    '$route.query.step': {
      handler: async function (step) {
        if (step) {
          this.formData = {
            ...this.formData,
            ...{
              first_name: await this.existingTenant.first_name,
              last_name: await this.existingTenant.last_name,
              email: await this.existingTenant.email,
              date_of_birth: await this.existingTenant.date_of_birth,
              mobile_number: await this.existingTenant.mobile_number,
            },
          };
          this.currentStep = await step;
          this.hideSuggestion = true;
        }
      },
      deep: true,
      immediate: true,
    },
    async tenantReview(value) {
      if (typeof (await value) !== 'undefined' && (await value) !== null) {
        if (this.isSubmitted) {
          await this.handleGenerateReport();
        }
      }
    },
    async pdfFile(value) {
      if ((await value) !== '') {
        const data = {
          refNo: await this.tenantReview?.reference_no,
          report: await value,
        };

        this.savePDFReport(data);

        this.pdfFile = '';
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    const formHasValue = Object.values(this.formData).length > 0;

    if (formHasValue && !this.isSubmitted) {
      toasts.confirm(
        'Do you really want to leave? You have unsaved changes.',
        ({ isConfirmed }) => {
          if (isConfirmed) {
            next();
          } else {
            next(false);
          }
        }
      );
    } else {
      next();
    }
  },
});
