
import { defineComponent } from 'vue';
import InputTag from '@/components/InputTag.vue';
import { email as emailRegex } from '@/utils/regex';
import { mapActions, mapGetters } from 'vuex';
import { Getters, Actions } from '@/store/enums/TenantReviewEnums';
import toasts from '@/utils/toasts';
import { ElMessage } from 'element-plus';
import { numberFormat } from '@/utils/text';
import { TenantReview } from '@/models/TenantReviewModel';

export default defineComponent({
  components: { InputTag },
  props: {
    link: {
      type: String,
      default: '',
    },
    refNo: {
      type: String,
      default: '',
    },
    currentTenantReview: {
      type: Object,
      default: () => {
        return {} as TenantReview;
      },
    },
  },
  data() {
    return {
      emails: [],
      isSendingEmail: false,
      pdfFile: '',
      averageRating: '0',
    };
  },
  async mounted() {
    this.computeAverageRating();
  },
  computed: {
    ...mapGetters({
      actionError: Getters.GET_TENANT_REVIEW_ACTION_ERROR,
      tenantReviews: Getters.GET_TENANT_REVIEWS,
    }),
    emailValidRegex() {
      return emailRegex;
    },
    googleStaticMapImageSrc() {
      const src = (this.currentTenantReview as any)?.map_static_src;
      return src != '' ? src : require('@/assets/new-design/images/map.png');
    },
    reviewsCount() {
      return typeof this.tenantReviews !== 'undefined'
        ? this.tenantReviews.length
        : 0;
    },
    isNotPublished(){
      return this.currentTenantReview.status == 'pending' || this.currentTenantReview.is_published == 'no'
    },
    submissionMessage(){
      if(this.isNotPublished){
        return {
          title: 'Approval Needed for Tenant Reference.',
          text: 'Tenant Reference is subject for Due Authorization by the authorized person before being published.'
        }
      }

      return {
        title: 'Successfully submitted tenant reference.',
        text: 'Take a look at the tenant profile.'
      }
    }
  },
  methods: {
    ...mapActions({
      sendToEmails: Actions.SEND_TENANT_REVIEW_TO_EMAILS,
    }),

    computeAverageRating() {
      if (
        typeof this.tenantReviews !== 'undefined' &&
        this.tenantReviews.length > 0
      ) {
        let sumRating = 0;
        this.tenantReviews.map((review: TenantReview) => {
          sumRating = sumRating + parseFloat(review.average_rating);
        }, 0);

        const average = `${sumRating / this.tenantReviews.length}`;
        this.averageRating = numberFormat(average);
      }
    },
    copyLink() {
      navigator.clipboard.writeText(this.link);
      ElMessage({
        message: 'Link copied.',
        type: 'success',
      });
    },
    clickSubmitNewTenantReview() {
      this.$emit('createNewTenantReview', true);
    },
    clickSendReviewToEmails() {
      if (!this.refNo || !this.emails.length) {
        return false;
      }

      this.isSendingEmail = true;

      const payload = {
        refNo: this.refNo,
        emails: Object.values(this.emails),
      };

      this.sendToEmails(payload)
        .then(() => {
          toasts.success('Tenant Review Successfully Sent');
          this.emails = [];
        })
        .catch(() => {
          const { errors, message } = this.actionError;
          toasts.error(errors, message);
        })
        .finally(() => {
          this.isSendingEmail = false;
        });
    },
  },
});
